<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid bg-primary px-4" style="z-index: 400">
    <a [routerLink]="['/']" class="navbar-brand" style="max-width: 120px;">
      <img *ngIf="!platform" alt="logo" class="w-100" src="assets/images/logo-white.png">
      <img (error)="onImageError($event)" *ngIf="platform" alt="logo"
           class="w-100" [src]=" environment.api_url + platform.logoUrl">
    </a>

    <app-selector-platform *ngIf="rolePermissions && rolePermissions['plataforms'].pageAccess"
                           class="ms-3 d-block d-lg-none"></app-selector-platform>
    <button
      (click)="navbarCollapsed = !navbarCollapsed"
      [attr.aria-expanded]="!navbarCollapsed"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="navbar-toggler"
      data-bs-target="#navbarNav"
      data-bs-toggle="collapse"
      type="button"
    >
      <span class="navbar-toggler-icon"></span>

    </button>
    <div *ngIf="rolePermissions" class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto pe-5">
        <li class="nav-item">
          <a *ngIf="rolePermissions['dashboard'].pageAccess"
             [routerLinkActive]="'active'"
             [routerLink]="['/dashboard']"
             class="nav-link"
          >{{ 'DASHBOARD.navbar'  | transloco }}</a
          >
        </li>
        <li class="nav-item">
          <a *ngIf="rolePermissions['users'].pageAccess"
             [routerLinkActive]="'active'"
             [routerLink]="['/users']"
             class="nav-link"
          >{{ 'USERS.navbar'  | transloco }}</a
          >
        </li>
        <li class="nav-item">
          <a *ngIf="rolePermissions['plataforms'].pageAccess"
             [routerLinkActive]="'active'"
             [routerLink]="['/platforms']"
             class="nav-link"
          >{{ 'PLATFORMS.navbar'  | transloco }}</a
          >
        </li>
        <li class="nav-item">
          <a *ngIf="rolePermissions['projects'].pageAccess"
             [routerLinkActive]="'active'"
             [routerLink]="['/categories']"
             class="nav-link"
          > {{ 'PROJECTS.navbar'  | transloco }}</a
          >
        </li>
        <li class="nav-item">
          <a *ngIf="rolePermissions['activities'].pageAccess"
             [routerLinkActive]="'active'"
             [routerLink]="['/activities']"
             class="nav-link"
          >{{ 'ACTIVITIES.navbar'  | transloco }}</a>
        </li>

        <li class="nav-item">
          <a *ngIf="rolePermissions['groups'].pageAccess"
             [routerLinkActive]="'active'"
             [routerLink]="['/groups']"
             class="nav-link"
          >{{ 'GROUPS.navbar'  | transloco }}</a
          >
        </li>

        <li class="nav-item">
          <a *ngIf="authStore.user()?.role == 'ADMIN_GLOBAL'"
             [routerLinkActive]="'active'"
             [routerLink]="['/licences']"
             class="nav-link"
          >{{ 'LICENCES.navbar'  | transloco }}</a
          >
        </li>

        <li class="nav-item">
          <a *ngIf="authStore.user()?.role != 'ADMIN_GLOBAL' && rolePermissions['licences'].pageAccess" [routerLinkActive]="'active'"
            [routerLink]="['/buy']"
            class="nav-link"
          >{{ 'BUY.navbar'  | transloco }}</a
          >
        </li>

        <li class="nav-item">
          <a *ngIf="authStore.user()?.role != 'ADMIN_GLOBAL' && rolePermissions['myLicences'].pageAccess" [routerLinkActive]="'active'"
            [routerLink]="['/my-licences']"
            class="nav-link"
          >{{ 'MY-LICENCES.navbar'  | transloco }}</a
          >
        </li>

        <!--<li *ngIf="rolePermissions['projectsLicences'].pageAccess || rolePermissions['activitiesLicences'].pageAccess" class="nav-item "
            ngbDropdown>
          <button type="button" class="btn text-white nav-item border-0 pt-2 nav-link" id="dropdownBasic1" ngbDropdownToggle>
            {{ 'LICENCES.navbar'  | transloco }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="bg-primary">
            <a *ngIf="rolePermissions['activitiesLicences'].pageAccess" [routerLinkActive]="'active'"
               [routerLink]="['/activities-licences']"
               class="nav-link bg-transparent px-3"
               ngbDropdownItem> {{ 'ACTIVITIES-LICENCES.navbar'  | transloco }} </a>
            <a *ngIf="rolePermissions['projectsLicences'].pageAccess"
               [routerLinkActive]="'active'"
               [routerLink]="['/projects-licences']"
               class="nav-link px-3">{{ 'PROJECTS-LICENCES.navbar'  | transloco }} </a>
          </div>
        </li>-->


        <!--         <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/projects-licences']"
                    class="nav-link"
                    >projects_licences</a
                  >
                </li>

                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/groups-projects']"
                    class="nav-link"
                    >groups_projects</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/groups-activities']"
                    class="nav-link"
                    >groups_activities</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/users-plataforms']"
                    class="nav-link"
                    >users_plataforms</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/users-projects']"
                    class="nav-link"
                    >users_projects</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/users-activities']"
                    class="nav-link"
                    >users_activities</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/users-groups']"
                    class="nav-link"
                    >users_groups</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/users-projects-licences']"
                    class="nav-link"
                    >users_projects_licences</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    [routerLinkActive]="'active'"
                    [routerLink]="['/users-activities-licences']"
                    class="nav-link"
                    >users_activities_licences</a
                  >
                </li> -->
      </ul>


      <app-selector-platform *ngIf="rolePermissions['plataforms'].pageAccess"
                             class="ms-3 d-none d-lg-block"></app-selector-platform>

      <div class="ms-4 d-flex gap-2 mt-2 mt-lg-0">

        <!--Theme dropdown-->
        <div class="w-50 d-none" ngbDropdown>
          <button class="btn text-white border-0 w-100" ngbDropdownToggle>
            <i [classList]="getThemeIcon()" class="bi"></i> {{ getThemeName() }}
          </button>
          <div class="dropdown-menu-end" ngbDropdownMenu>
            <button (click)="changeTheme('light')" ngbDropdownItem>
              <i class="bi bi-brightness-high-fill"></i> Light
            </button>
            <button (click)="changeTheme('dark')" ngbDropdownItem>
              <i class="bi bi-moon-stars-fill"></i> Dark
            </button>
            <button (click)="changeTheme('auto')" ngbDropdownItem>
              <i class="bi bi-circle-half"></i> Auto
            </button>
          </div>
        </div>

        <!--Navbar profile dropdown-->
        <div class="w-50" ngbDropdown>
          <button class="btn text-white border-0 w-100" ngbDropdownToggle>
            <i class="fa-solid fa-user me-2"></i>{{ firstname() }}
          </button>
          <div class="dropdown-menu-end" ngbDropdownMenu>
            <a
              [routerLinkActive]="'active'"
              [routerLink]="['/profile']"
              class="nav-link">
              <button ngbDropdownItem>
                {{ 'PROFILE.navbar'  | transloco }}
              </button>
            </a>
            <button (click)="logout()" ngbDropdownItem>Cerrar sesión</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
